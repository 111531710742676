import { object, string, number } from 'yup';
import * as constants from '@iso/config/constant';

// Custom validation for latitude and longitude specific to Singapore
const latitudeSchema = number()
  .nullable()
  .optional()
  .typeError('Latitude must be a valid number')
  .min(1.1, 'Latitude must be at least 1.1')
  .max(1.5, 'Latitude must be at most 1.5');

const longitudeSchema = number()
  .nullable()
  .optional()
  .typeError('Longitude must be a valid number')
  .min(103.6, 'Longitude must be at least 103.6')
  .max(104.1, 'Longitude must be at most 104.1');

const validationSchema = object({
  category: number()
    .nullable()
    .oneOf(Object.keys(constants.category).map(Number), 'Invalid category')
    .required('Category is required'),

  type: number()
    .nullable()
    .oneOf(Object.keys(constants.propertyTypes).map(Number), 'Invalid property type')
    .required('Property type is required'),

  sub_type_id: number().nullable().required('Sub-Type is required'),

  premises_type_id: number().nullable().optional(),
  property_id: number().nullable().optional(),

  property_name: string().nullable().required('Property name is required'),

  block: string().nullable().optional(),

  street_name: string().nullable().required('Street name is required'),

  postal_code: number().nullable().min(5, 'Postal code must be at least 5 digits').optional(),
  state_id: number().nullable().optional(),
  district_id: number().nullable().optional(),
  locality: string().nullable().optional(),
  year_built: string().nullable().optional(),
  tenure: string().nullable().optional(),
  highest_storey: string().nullable().optional(),
  total_units: string().nullable().optional(),
  total_towers: string().nullable().optional(),
  developer: string().nullable().optional(),
  mrt_info: string().nullable().optional(),

  latitude: latitudeSchema,
  longitude: longitudeSchema,

  nearest_mrt: string().nullable().optional(),
  mrt_distance: number().nullable().optional()
});

export const ListInformation = object().shape({
  listing_type: string().required('Listing type is required'),
  listing_title: string().required('Main listing title is required.'),
  price: string()
    // .matches(/^[0-9]+$/, 'Price must be numbers')
    .required('Price is required'),
  floor_area: string()
    .matches(/^[0-9]+$/, 'Floor area must be numbers')
    .required('Floor area is required'),
  land_area: string().matches(/^[0-9]+$/, 'Land area must be numbers'),
  availability: string().trim().optional(),
  bedrooms: string().test('conditional-validity-required', function (value) {
    const { category } = this.parent;
    const isValidityRequired = category === constants.categoryNumbers.Residential;
    if (isValidityRequired && !value) {
      return this.createError({ message: 'No. of bedrooms is required' });
    }
    if (value && Number(value) > 15) {
      return this.createError({ message: 'No. of bedrooms must not exceed 15' });
    }
    return true;
  }),
  bathrooms: string().test('conditional-validity-required', function (value) {
    const { category } = this.parent;
    const isValidityRequired = category === constants.categoryNumbers.Residential;
    if (isValidityRequired && !value) {
      return this.createError({ message: 'No. of bathrooms is required' });
    }
    if (value && Number(value) > 15) {
      return this.createError({ message: 'No. of bathrooms must not exceed 15' });
    }
    return true;
  }),

  floor_level: string()
    .trim()
    .matches(/^[0-9]+$/, 'Floor level must be numbers')
    .optional(),
  tower_name: string().optional(),
  is_main_door_facing: string().optional(),
  is_living_room_window_facing: string().optional(),
  furnished: string().optional(),
  reno_condition: string().optional(),
  highest_storey: string().optional(),
  fitting_type: string().optional()
});

export const MediaInformationSchema = object().shape({
  youtube_url: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  youtube_url_1: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    }),
  virtual_tour_url: string()
    .optional()
    .test('is-youtube-link', 'Invalid youtube link', (value) => {
      if (!value) return true;
      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return youtubeUrlRegex.test(value);
    })
});

export const initialValues = {
  realtor_id: '',
  type: '',
  category: '',
  sub_type_id: '',
  premises_type_id: '',
  property_name: '',
  block: '',
  street_name: '',
  postal_code: '',
  tenure: '',
  mrt_info: '',
  state_id: '',
  district_id: '',
  locality: '',
  year_built: '',
  highest_storey: '',
  total_units: '',
  total_towers: '',
  developer: '',
  latitude: '',
  longitude: ''
};

export const ListInformationInitialValues = {
  category: '',
  listing_type: '',
  listing_title: '',
  price: '',
  floor_area: '',
  land_area: '',
  availability: '',
  bedrooms: '',
  bathrooms: '',
  floor_level: '',
  tower_name: '',
  is_main_door_facing: '',
  is_living_room_window_facing: '',
  furnished: '',
  reno_condition: '',
  fitting_type: '',
  is_new: false,
  facilities: [],
  amenities: []
};

export const listingMediaInformationInitialValues = {
  youtube_url: '',
  youtube_url_1: '',
  virtual_tour_url: ''
};

export default validationSchema;
