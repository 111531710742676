export const ADMINS = ['Admin'];
export const SUBADMINS = ['Sub Admin'];
export const rolesNumberToName = {
  1: 'super_admin',
  2: 'sub_admin',
  3: 'user',
  4: 'realtor'
};
export const roleNameToNumber = {
  super_admin: '1',
  sub_admin: '2',
  user: '3',
  realtor: '4'
};
export const perkTypeNumberToName = {
  1: 'Facility',
  2: 'Amenity'
};
export const perkTypeNameToNumber = {
  Facility: '1',
  Amenity: '2'
};
export const adPointTransactionTypeName = {
  1: 'Subscription plan',
  2: 'Ad points top-up',
  3: 'Premium realtor ads space booking',
  4: 'Team Building ad space booking',
  5: 'Project Sales ad Space booking',
  6: 'Publish listing',
  7: 'Supercharge listing',
  8: 'Mobile leads',
  9: 'Referral bonus',
  10: 'Coupon code bonus',
  11: 'Realtor signup bonus',
  12: 'Unused ad points carried over',
  13: 'Unused ad points lost'
};
export const adPointTransactionType = {
  Credit: 1,
  Debit: 2
};
export const moduleNumberToName = {
  1: 'Estate / District',
  2: 'Sub Admin',
  3: 'Promo Code',
  4: 'Rate Table',
  5: 'Perks',
  6: 'Metro Station',
  7: 'Normal User',
  8: 'Realtor',
  9: 'Billing',
  10: 'FAQ',
  11: 'WantedAds',
  12: 'Owner Listing',
  13: 'New Launch',
  14: 'Residential Listing',
  15: 'Commercial Listing',
  16: 'Ad Space',
  17: 'Business Report',
  18: 'Google Ads',
  19: 'Residential Master',
  20: 'Commercial Master',
  21: 'Verify New Property',
  22: 'Static Pages',
  23: 'Agencies',
  24: 'Ad Space Master',
  25: 'Ad Point Transactions',
  26: 'Update System Images'
};
export const moduleNameToNumber = {
  'Estate / District': '1',
  'Sub Admin': '2',
  'Promo Code': '3',
  'Rate Table': '4',
  Perks: '5',
  'Metro Station': '6',
  'Normal User': '7',
  Realtor: '8',
  Billing: '9',
  FAQ: '10',
  'Wanted Ads': '11',
  'Owner Listing': '12',
  'New Launch': '13',
  'Residential Listing': '14',
  'Commercial Listing': '15',
  'Ad Space': '16',
  'Business Report': '17',
  'Google Ads': '18',
  'Residential Master': '19',
  'Commercial Master': '20',
  'Verify New Property': '21',
  'Static Pages': '22',
  Agencies: '23',
  'Ad Space Master': '24',
  'Ad Point Transactions': '25',
  'Update System Images': '26'
};
export const categoryNumbers = {
  Residential: '1',
  Commercial: '2'
};
export const categoryTypeNumberToName = {
  1: 'Residential',
  2: 'Commercial'
};
export const estateDistrictNumbers = {
  Estate: 1,
  District: 2
};
export const typeNumbers = {
  HDB: '1',
  Condo: '2',
  Landed: '3',
  'Retail & Services': '4',
  'Food & Beverage': '5',
  'Office & Education': '6',
  Industrial: '7',
  Commercial: '8'
};
export const propertyMasterTypeNumbers = {
  HDB: '1',
  'Condo/Landed': '2',
  Location: '3',
  Commercial: '4'
};
export const propertyTypeNumberToName = {
  1: 'HDB',
  2: 'Condo',
  3: 'Landed',
  4: 'Retail & Services',
  5: 'Food & Beverage',
  6: 'Office & Education',
  7: 'Industrial',
  8: 'Commercial'
};
export const plansNumber = {
  Subscription: '1',
  'Top Up': '2',
  Supercharge: '3',
  'Mobile Lead': '4',
  'Property Listing': '5',
  Tax: '6',
  Referrer: '7',
  Referee: '8',
  'Ad Space Rate Group': '9',
  'Ad Points': '10'
};
export const plansName = {
  1: 'Subscription',
  2: 'Top Up',
  3: 'Supercharge',
  4: 'Mobile Lead',
  5: 'Property Listing',
  6: 'Tax',
  7: 'Referrer',
  8: 'Referee',
  9: 'Ad Space Rate Group',
  10: 'Ad Points'
};
export const plansForBilling = {
  1: 'Subscription Plan',
  2: 'Top Up Plan',
  5: 'Property Listing'
};
export const planNameToId = {
  'Owner Listing': 9,
  'Wanted Ad Listing': 10
};
export const pageName = {
  1: 'Market place page',
  2: 'Realtor corner',
  3: 'Market place & Realtor corner'
};
export const propertyListingTypes = {
  1: 'For Sale',
  2: 'For Rent',
  3: 'For Room Rental',
  4: 'For Takeover'
};
export const propertyTypes = {
  1: 'HDB',
  2: 'Condo',
  3: 'Landed',
  4: 'Retail & Services',
  5: 'Food & Beverage',
  6: 'Office & Education',
  7: 'Industrial'
};

export const propertyMasterTypes = {
  1: 'HDB',
  2: 'Condo/Landed',
  3: 'Location',
  4: 'Commercial'
};

export const powerups = {
  1: 'Supercharge 1',
  2: 'Supercharge 2',
  3: 'Supercharge 3',
  4: 'Supercharge 4'
};

export const renoCondition = {
  1: 'Original Condition',
  2: 'Renovated',
  3: 'Well Renovated',
  4: 'Newly Renovated',
  5: 'Designer renovated'
};

export const fittingType = {
  1: 'Bare',
  2: 'Partially Fitted',
  3: 'Fully Fitted'
};

export const furnishingType = {
  1: 'Unfurnished',
  2: 'Partially Furnished',
  3: 'Fully Furnished'
};

export const florrLevel = {
  1: 'Ground Floor',
  2: 'Between 1-5 Floor',
  3: 'Between 6-10 Floor',
  4: 'Between 11-15 Floor',
  5: 'Between 16-20 Floor',
  6: 'Between 21-25 Floor',
  7: 'Between 26-30 Floor',
  8: 'Between 31-35 Floor',
  9: 'Between 36-40 Floor',
  10: 'Above 40+ Floor',
  11: 'Basement',
  12: '6 Floor & above'
};

export const residentialPropertyTypes = {
  1: 'HDB',
  2: 'Condo',
  3: 'Landed'
};

export const commercialPropertyTypes = {
  4: 'Retail & Services',
  5: 'Food & Beverage',
  6: 'Office & Education',
  7: 'Industrial'
};

export const newLaunchStatusNumber = {
  Inactive: '1',
  Active: '2',
  Draft: '3'
};

export const newLaunchStatusNumberToName = {
  1: 'Inactive',
  2: 'Active',
  3: 'Draft'
};

export const propertyCategory = {
  Residential: 1,
  Commercial: 2
};

export const estateOrDistrict = {
  Estate: 1,
  District: 2
};

export const propertyStatus = {
  Active: '2',
  Deactive: '3',
  Expired: '4'
};

export const propertyStatusNumber = {
  2: 'Active',
  3: 'Deactive',
  4: 'Expired'
};

export const propertyPremiumQuality = {
  Premium: 1,
  Normal: 0
};

export const propertyIsNew = {
  New: 1,
  Old: 0
};

export const propertyIsNewNumber = {
  0: 'Old',
  1: 'New'
};

export const wantedAdOwnerListingStatus = {
  Draft: 1,
  Active: 2,
  Deactive: 3,
  Expired: 4,
  'Review in progress': '5',
  Rejected: '6'
};

export const propertyMasterStatusNumber = {
  2: 'Active',
  1: 'Deactive'
};

export const propertyMasterStatus = {
  Active: '2',
  Deactive: '1'
};

export const propertyIsAdSpace = {
  'Ad Space': '1',
  'Not Ad Space': '0'
};

export const propertyIsAdSpaceNumber = {
  1: 'Ad Space',
  0: 'Not Ad Space'
};

export const propertyIsTaggerNumber = {
  1: 'Tagger',
  0: 'Not Tagger'
};

export const residentialTypeMasterNumber = {
  1: 'HDB',
  2: 'Condo/Landed',
  3: 'Location'
};

export const residentialTypeMaster = {
  HDB: '1',
  'Condo/Landed': '2',
  Location: '3'
};

export const wantedAdOwnerListingStatusName = {
  1: 'Draft',
  2: 'Active',
  3: 'Deactive',
  4: 'Expired'
};

export const adSpaceType = {
  'Premium Realtor': 1,
  'Team Building': 2,
  'Project Sales': 3
};

export const adSpaceTypeName = {
  1: 'Premium Realtor',
  2: 'Team Building',
  3: 'Project Sales'
};

export const adSpacePage = {
  1: 'Home Page',
  2: 'New Launch Page',
  3: 'Owner Listing Page',
  4: 'Wanted Ad Page',
  5: 'Main Residential Page',
  6: 'Main Commercial Page',
  7: 'Residential Project',
  8: 'Commercial Project',
  9: 'New Launch Project',
  10: 'Residential Listing Page',
  11: 'Commercial Listing Page'
};

export const adSpacePropertyType = {
  1: 'HDB',
  2: 'Condo',
  3: 'Landed',
  4: 'Retail & Services',
  5: 'Food & Beverage',
  6: 'Office & Education',
  7: 'Industrial'
};

export const adSpaceStatus = {
  Active: 1,
  Inactive: 2,
  Expired: 3
};

export const adSpaceStatusName = {
  1: 'Active',
  2: 'Inactive',
  3: 'Expired'
};
export const stateDistrictStatus = {
  Active: 1,
  Inactive: 0
};

export const adSpaceTableStatus = {
  Inactive: 1,
  Active: 2
};

export const adSpaceMaxSlots = {
  'Premium Realtor': 18,
  'Elite Team': 100,
  'Project Tagger': 18
};
export const faqPage = {
  'Market place page': '1',
  'Realtor corner': '2',
  Both: '3'
};

export const faqGroup = {
  'Group 1': '1',
  'Group 2': '2',
  'Group 3': '3',
  'Group 4': '4',
  'Group 5': '5'
};

export const faqGroupNumberToName = {
  1: 'Group 1',
  2: 'Group 2',
  3: 'Group 3',
  4: 'Group 4',
  5: 'Group 5'
};

export const generalStatus = {
  Inactive: '1',
  Active: '2'
};
export const generalStatusName = {
  1: 'Inactive',
  2: 'Active'
};
export const metroLineName = {
  1: 'East West Line',
  2: 'North East Line',
  3: 'Circle Line',
  4: 'Down Town Line',
  5: 'North South Line',
  6: 'Thomson-East Cost Line'
};
export const metroLine = {
  'East West Line': '1',
  'North East Line': '2',
  'Circle Line': '3',
  'Down Town Line': '4',
  'North South Line': '5',
  'Thomson-East Cost Line': '6'
};
export const mobileStatus = {
  1: 'Verified',
  0: 'Unverified'
};
export const wantedAdListingType = {
  'To Rent': 1,
  'To Buy': 2
};
export const wantedAdListingTypeName = {
  1: 'To Rent',
  2: 'To Buy'
};
export const ownerListingType = {
  'For Sale': 1,
  'For Rent': 2
};
export const ownerListingTypeName = {
  1: 'For Sale',
  2: 'For Rent'
};
export const promocodeStatusName = {
  1: 'Inactive',
  2: 'Active',
  3: 'Expired'
};
export const promocodeStatus = {
  Inactive: '1',
  Active: '2',
  Expired: '3'
};
export const subscriptionStatusName = {
  2: 'Active',
  3: 'Expired'
};
export const subscriptionStatus = {
  Active: '2',
  Expired: '3',
  'No subscription': '4'
};
export const billingAccountTypeName = {
  3: 'Normal user',
  4: 'Realtor Account'
};
export const wantedOwnerStatusFilterName = {
  2: 'Active',
  3: 'Inactive'
};
export const promocodePlansName = {
  1: 'Subscription',
  2: 'Top Up'
};
export const residentialListingTypes = {
  1: 'For Sale',
  2: 'For Rent',
  3: 'For Room Rental'
};
export const commercialListingTypes = {
  1: 'For Sale',
  2: 'For Rent',
  4: 'For Takeover'
};
export const reportDuration = {
  'Yearly Report': '1',
  'Quarterly Report': '2',
  'Monthly Report': '3',
  'Weekly Report': '4'
};
export const yearToStartFrom = 2020;
export const monthNumbers = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12
};
export const GoogleAdsField = {
  1: 'Residential vertical 1',
  2: 'Residential vertical 2',
  3: 'Residential vertical 3',
  4: 'Residential horizontal',
  5: 'Commercial vertical 1',
  6: 'Commercial vertical 2',
  7: 'Commercial vertical 3',
  8: 'Commercial horizontal',
  9: 'New Launch horizontal',
  10: 'Owner Listing vertical 1',
  11: 'Owner Listing vertical 2',
  12: 'Owner Listing vertical 3',
  13: 'Owner Listing horizontal',
  14: 'Wanted Ads vertical 1',
  15: 'Wanted Ads vertical 2',
  16: 'Wanted Ads vertical 3',
  17: 'Wanted Ads horizontal',
  18: 'Connect Realtor horizontal',
  19: 'Elite Teams horizontal',
  20: 'Project Tagger horizontal'
};
export const commercialMasterType = {
  3: 'Location',
  4: 'Commercial'
};
export const ownerListPropTypeNumberToName = {
  1: 'HDB',
  2: 'Condo',
  3: 'Landed',
  4: 'Commercial',
  5: 'Industrial'
};
export const staticPageFieldNameToNumber = {
  'about-us': 2,
  'our-mission': 3,
  'privacy-policy': 4,
  'terms-of-use': 5
};
export const staticPageFieldName = {
  2: 'About Us',
  3: 'Mission Statement',
  4: 'Privacy Policy',
  5: 'Terms Of Use'
};
export const propertyFieldName = {
  1: 'Residentail Property',
  2: 'Commercial Property'
};
export const propertyMasterFieldName = {
  1: 'Residential Master',
  2: 'Commercial Master'
};
export const usersFieldName = {
  1: 'Realtor',
  2: 'Sub Admin',
  3: 'Normal User'
};
export const pricingFieldName = {
  1: 'Rate Table',
  2: 'Promocode'
};
export const settingsFieldName = {
  1: 'FAQ',
  2: 'Google ads'
};
export const mainDoorFacingDirections = {
  1: 'North',
  2: 'East',
  3: 'West',
  4: 'South',
  5: 'North East',
  6: 'South East',
  7: 'North West',
  8: 'South West'
};
export const verification_status_number = {
  1: 'Pending',
  2: 'Valid',
  3: 'Invalid'
};
export const verification_type_number = {
  1: 'Automated',
  2: 'Manual'
};
export const verificationStatus = {
  Pending: 1,
  Valid: 2,
  Invalid: 3
};
export const verificationType = {
  Automated: 1,
  Manual: 2
};
export const florrLevelPropertyListing = {
  1: 'Ground Floor',
  2: 'Between 1-5 Floor',
  3: 'Between 6-10 Floor',
  4: 'Between 11-15 Floor',
  5: 'Between 16-20 Floor',
  6: 'Between 21-25 Floor',
  7: 'Between 26-30 Floor',
  8: 'Between 31-35 Floor',
  9: 'Between 36-40 Floor',
  10: 'Above 40+ Floor'
};

export const category = {
  1: 'Residential',
  2: 'Commercial'
};

export const propertyListingStatusWithDraft = {
  Draft: '1',
  Active: '2',
  Deactive: '3',
  Expired: '4'
};

export const propertyListingCreateBy = {
  1: 'Realtor',
  2: 'Admin'
};

export const timeSheet = [
  { label: '00:00 AM', value: '00:00:00' },
  { label: '00:10 AM', value: '00:10:00' },
  { label: '00:20 AM', value: '00:20:00' },
  { label: '00:30 AM', value: '00:30:00' },
  { label: '00:40 AM', value: '00:40:00' },
  { label: '00:50 AM', value: '00:50:00' },
  { label: '01:00 AM', value: '01:00:00' },
  { label: '01:10 AM', value: '01:10:00' },
  { label: '01:20 AM', value: '01:20:00' },
  { label: '01:30 AM', value: '01:30:00' },
  { label: '01:40 AM', value: '01:40:00' },
  { label: '01:50 AM', value: '01:50:00' },
  { label: '02:00 AM', value: '02:00:00' },
  { label: '02:10 AM', value: '02:10:00' },
  { label: '02:20 AM', value: '02:20:00' },
  { label: '02:30 AM', value: '02:30:00' },
  { label: '02:40 AM', value: '02:40:00' },
  { label: '02:50 AM', value: '02:50:00' },
  { label: '03:00 AM', value: '03:00:00' },
  { label: '03:10 AM', value: '03:10:00' },
  { label: '03:20 AM', value: '03:20:00' },
  { label: '03:30 AM', value: '03:30:00' },
  { label: '03:40 AM', value: '03:40:00' },
  { label: '03:50 AM', value: '03:50:00' },
  { label: '04:00 AM', value: '04:00:00' },
  { label: '04:10 AM', value: '04:10:00' },
  { label: '04:20 AM', value: '04:20:00' },
  { label: '04:30 AM', value: '04:30:00' },
  { label: '04:40 AM', value: '04:40:00' },
  { label: '04:50 AM', value: '04:50:00' },
  { label: '05:00 AM', value: '05:00:00' },
  { label: '05:10 AM', value: '05:10:00' },
  { label: '05:20 AM', value: '05:20:00' },
  { label: '05:30 AM', value: '05:30:00' },
  { label: '05:40 AM', value: '05:40:00' },
  { label: '05:50 AM', value: '05:50:00' },
  { label: '06:00 AM', value: '06:00:00' },
  { label: '06:10 AM', value: '06:10:00' },
  { label: '06:20 AM', value: '06:20:00' },
  { label: '06:30 AM', value: '06:30:00' },
  { label: '06:40 AM', value: '06:40:00' },
  { label: '06:50 AM', value: '06:50:00' },
  { label: '07:00 AM', value: '07:00:00' },
  { label: '07:10 AM', value: '07:10:00' },
  { label: '07:20 AM', value: '07:20:00' },
  { label: '07:30 AM', value: '07:30:00' },
  { label: '07:40 AM', value: '07:40:00' },
  { label: '07:50 AM', value: '07:50:00' },
  { label: '08:00 AM', value: '08:00:00' },
  { label: '08:10 AM', value: '08:10:00' },
  { label: '08:20 AM', value: '08:20:00' },
  { label: '08:30 AM', value: '08:30:00' },
  { label: '08:40 AM', value: '08:40:00' },
  { label: '08:50 AM', value: '08:50:00' },
  { label: '09:00 AM', value: '09:00:00' },
  { label: '09:10 AM', value: '09:10:00' },
  { label: '09:20 AM', value: '09:20:00' },
  { label: '09:30 AM', value: '09:30:00' },
  { label: '09:40 AM', value: '09:40:00' },
  { label: '09:50 AM', value: '09:50:00' },
  { label: '10:00 AM', value: '10:00:00' },
  { label: '10:10 AM', value: '10:10:00' },
  { label: '10:20 AM', value: '10:20:00' },
  { label: '10:30 AM', value: '10:30:00' },
  { label: '10:40 AM', value: '10:40:00' },
  { label: '10:50 AM', value: '10:50:00' },
  { label: '11:00 AM', value: '11:00:00' },
  { label: '11:10 AM', value: '11:10:00' },
  { label: '11:20 AM', value: '11:20:00' },
  { label: '11:30 AM', value: '11:30:00' },
  { label: '11:40 AM', value: '11:40:00' },
  { label: '11:50 AM', value: '11:50:00' },
  { label: '12:00 PM', value: '12:00:00' },
  { label: '12:10 PM', value: '12:10:00' },
  { label: '12:20 PM', value: '12:20:00' },
  { label: '12:30 PM', value: '12:30:00' },
  { label: '12:40 PM', value: '12:40:00' },
  { label: '12:50 PM', value: '12:50:00' },
  { label: '01:00 PM', value: '13:00:00' },
  { label: '01:10 PM', value: '13:10:00' },
  { label: '01:20 PM', value: '13:20:00' },
  { label: '01:30 PM', value: '13:30:00' },
  { label: '01:40 PM', value: '13:40:00' },
  { label: '01:50 PM', value: '13:50:00' },
  { label: '02:00 PM', value: '14:00:00' },
  { label: '02:10 PM', value: '14:10:00' },
  { label: '02:20 PM', value: '14:20:00' },
  { label: '02:30 PM', value: '14:30:00' },
  { label: '02:40 PM', value: '14:40:00' },
  { label: '02:50 PM', value: '14:50:00' },
  { label: '03:00 PM', value: '15:00:00' },
  { label: '03:10 PM', value: '15:10:00' },
  { label: '03:20 PM', value: '15:20:00' },
  { label: '03:30 PM', value: '15:30:00' },
  { label: '03:40 PM', value: '15:40:00' },
  { label: '03:50 PM', value: '15:50:00' },
  { label: '04:00 PM', value: '16:00:00' },
  { label: '04:10 PM', value: '16:10:00' },
  { label: '04:20 PM', value: '16:20:00' },
  { label: '04:30 PM', value: '16:30:00' },
  { label: '04:40 PM', value: '16:40:00' },
  { label: '04:50 PM', value: '16:50:00' },
  { label: '05:00 PM', value: '17:00:00' },
  { label: '05:10 PM', value: '17:10:00' },
  { label: '05:20 PM', value: '17:20:00' },
  { label: '05:30 PM', value: '17:30:00' },
  { label: '05:40 PM', value: '17:40:00' },
  { label: '05:50 PM', value: '17:50:00' },
  { label: '06:00 PM', value: '18:00:00' },
  { label: '06:10 PM', value: '18:10:00' },
  { label: '06:20 PM', value: '18:20:00' },
  { label: '06:30 PM', value: '18:30:00' },
  { label: '06:40 PM', value: '18:40:00' },
  { label: '06:50 PM', value: '18:50:00' },
  { label: '07:00 PM', value: '19:00:00' },
  { label: '07:10 PM', value: '19:10:00' },
  { label: '07:20 PM', value: '19:20:00' },
  { label: '07:30 PM', value: '19:30:00' },
  { label: '07:40 PM', value: '19:40:00' },
  { label: '07:50 PM', value: '19:50:00' },
  { label: '08:00 PM', value: '20:00:00' },
  { label: '08:10 PM', value: '20:10:00' },
  { label: '08:20 PM', value: '20:20:00' },
  { label: '08:30 PM', value: '20:30:00' },
  { label: '08:40 PM', value: '20:40:00' },
  { label: '08:50 PM', value: '20:50:00' },
  { label: '09:00 PM', value: '21:00:00' },
  { label: '09:10 PM', value: '21:10:00' },
  { label: '09:20 PM', value: '21:20:00' },
  { label: '09:30 PM', value: '21:30:00' },
  { label: '09:40 PM', value: '21:40:00' },
  { label: '09:50 PM', value: '21:50:00' },
  { label: '10:00 PM', value: '22:00:00' },
  { label: '10:10 PM', value: '22:10:00' },
  { label: '10:20 PM', value: '22:20:00' },
  { label: '10:30 PM', value: '22:30:00' },
  { label: '10:40 PM', value: '22:40:00' },
  { label: '10:50 PM', value: '22:50:00' },
  { label: '11:00 PM', value: '23:00:00' },
  { label: '11:10 PM', value: '23:10:00' },
  { label: '11:20 PM', value: '23:20:00' },
  { label: '11:30 PM', value: '23:30:00' },
  { label: '11:40 PM', value: '23:40:00' },
  { label: '11:50 PM', value: '23:50:00' }
];

export const planType = {
  subscription: '1',
  top_up: '2',
  power_up: '3',
  mobile_ead: '4',
  property_listing: '5',
  tax: '6',
  referrer: '7',
  referee: '8',
  ad_space_rate_group: '9',
  ad_points: '10'
};
export const adSpaceTypeData = [
  { value: '1', label: 'Premium realtor' },
  { value: '2', label: 'Team Building' },
  { value: '3', label: 'Project Sales' }
];
export const adSpaceTypeToNumber = {
  'Premium realtor': '1',
  'Elite team': '2',
  'Project tagger': '3'
};
export const adSpacePageTypeToNumber = {
  'Home page': '1',
  'New Launch listing': '2',
  'Owner listing': '3',
  'Wanted Ads': '4',
  'Residential listing': '5',
  'Commercial listing': '6',
  'Residential Projects': '7',
  'Commercial Projects': '8',
  'New Launch Projects': '9',
  'Residential Listing Page': '10',
  'Commercial Listing Page': '11'
};
export const subPlanStatus = {
  loading: 1,
  active: 2,
  inActive: 3
};
export const estateDistrictTypeToNumber = {
  Estate: '1',
  District: '2'
};
export const adSpacePageType = [
  { value: '1', label: 'Home page' },
  { value: '2', label: 'New Launch listing' },
  { value: '3', label: 'Owner listing' },
  { value: '4', label: 'Wanted Ads' },
  { value: '5', label: 'Residential listing' },
  { value: '6', label: 'Commercial listing' },
  { value: '7', label: 'Residential Projects' },
  { value: '8', label: 'Commercial Projects' },
  { value: '9', label: 'New Launch Projects' },
  { value: '10', label: 'Residential Listing Page' },
  { value: '11', label: 'Commercial Listing Page' }
];
export const adSpaceSubTypeToNumber = {
  HDB: '1',
  Condo: '2',
  Landed: '3',
  'Retail & Services': '4',
  'Food & Beverage': '5',
  'Office & Education': '6',
  Industrial: '7'
};

export const systemImageType = {
  'Home Page Banner': 1,
  'Signup/Login Image': 2
};

export const systemImageTypeNumber = {
  1: 'Home Page Banner',
  2: 'Signup/Login Image'
};
